/**
 * Storages
 */
export const AUTH_STORE = "auth-storage";
export const PARTNER_STORE = "partner-settings";
export const CART_STORE = "cart";
export const LANGUAGE_STORE = "language";
export const PRODUCTS_STORE = "products";
export const POLICIES_STORE = "policies";
export const USER_PERSONAL_DETAILS_STORE = "user-personal_details";
export const QUESTIONS_STORE = "questions";
export const ANSWERS_STORE = "answers";
export const STEPS_STORE = "steps";
export const USER_CHOICES_STORE = "user-choice";
export const CANCEL_POLICY_STORE = "cancel-policy";
export const SURVEY_STORE = "survey_store";
export const SIMPLE_SAT_STORE = "simple-sat";
export const TITLE_STORE = "title";
export const CHECKIN_STORE = "checkin";

// Survey types
export const SURVEY_TYPES = {
  PURCHASE_POLICY: {
    route: "purchase-policy",
    surveyType: "Purchase insurance",
  },
  CANCEL_POLICY: {
    route: "cancel-policy",
    surveyType: "Cancel insurance",
  },
  GENERAL: {
    route: "general",
    surveyType: "Genaral",
  },
};

export const SYSTEM_NAMES: string[] = [
  "amount",
  "income",
  "aov_percentage",
  "aov_duration",
  "aov_waiting_time",
  "aov_mental_discount",
  "aov_indexation",
  "device_type",
];

export const CustomerEffortScoreResonAnswers: {
  value: string;
  option: string;
}[] = [
  { value: "1", option: "option1" },
  { value: "2", option: "option2" },
  { value: "3", option: "option3" },
  { value: "4", option: "option4" },
  { value: "5", option: "option5" },
];

// These are getting from simplesat.io
// https://api.simplesat.io/api/v1/questions
export const EXIT_SURVEY_QUESTIONS = {
  exit_survey_experience: {
    question_id: 88238,
  },
  exit_survey_suggestions: {
    question_id: 88948,
  },
  exit_survey_why: {
    question_id: 88243,
  },
};

export const CUSTOM_EFFORT_SCORE_QUESTIONS = {
  survey_experience: {
    question_id: 65126,
  },
  survey_reason: {
    question_id: 162395,
  },
};

export enum HeaderStepRouteEnum {
  INSURANCE = "insurance",
  PERSONALISE = "personalise",
  MEDICAL_HISTORY = "medicalHistory",
  PAYMENT = "payment",
  CHECKOUT = "checkout",
  CONFIGURE = "configure",
  KNOWLEDGE_TEST = "knowledgeTest",
  CALCULATE = "calculate",
  RESULT = "result",
}

export const SupportFileTypes = {
  image: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/vnd.dwg": [".dwg"],
    "image/x-xcf": [".xcf"],
    "image/jpx": [".jpx"],
    "image/apng": [".apng"],
    "image/gif": [".gif"],
    "image/webp": [".webp"],
    "image/x-canon-cr2": [".cr2"],
    "image/tiff": [".tif"],
    "image/bmp": [".bmp"],
    "image/vnd.ms-photo": [".jxr"],
    "image/vnd.adobe.photoshop": [".psd"],
    "image/x-icon": [".ico"],
    "image/heic": [".heic"],
    "image/avif": [".avif"],
  },
  pdf: {
    "application/pdf": [".pdf"],
  },
  documents: {
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.oasis.opendocument.text": [".odt"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "application/vnd.oasis.opendocument.presentation": [".odp"],
  },
};

export const DEVICE_TYPE_SMARTPHONE = "smartphone";
export const CONSUMER_ELECTRONICS_QUESTION_DEVICE_ID = "device_id";
export const CONSUMER_ELECTRONICS_QUESTION_DEVICE_IMEI = "device_imei";
export const CONSUMER_ELECTRONICS_QUESTION_DEVICE_TYPE = "device_type";
export const CONSUMER_ELECTRONICS_QUESTION_DEVICE_PRICE = "device_price";
export const CONSUMER_ELECTRONICS_HIDDEN_QUESTIONS = [
  CONSUMER_ELECTRONICS_QUESTION_DEVICE_TYPE,
  CONSUMER_ELECTRONICS_QUESTION_DEVICE_PRICE,
];
