import { formatToastErrors } from "@utils/toast-errors";
import { instance } from "./helpers/axios";

export const getQuestionsForProducts = async (productIds: string[]) => {
  try {
    const responses = await Promise.all(
      productIds.map(
        async (id) =>
          await instance.get("/questions", {
            params: {
              product: id,
            },
          })
      )
    );

    return responses.map((res) => res.data.result);
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
export const getQuizResults = async (externalId: string, productId: string) => {
  try {
    const response = await instance.get("/quiz-result", {
      params: {
        external_id: externalId,
        product: productId,
      },
    });
    return response.data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
