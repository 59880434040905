import { Flex } from "@chakra-ui/layout";
import { ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { rem } from "polished";
import { FC } from "react";
import { ActionIconTypes, Background } from "../layout/background";
import { useColors } from "hooks/useColors";
import { usePartnerStore } from "store/partner";
import Editor from "features/editor";
import { MODAL_COLOR_FIELDS } from "src/constants";
import { useAuthStore } from "store/authentication";

const ModalContentWrapper: FC<ModalContentWrapperProps> = ({ children }) => {
  const { getColor } = useColors();
  const { admin } = useAuthStore();
  const { enable_visual_effects, name } = usePartnerStore();
  return (
    <>
      <ModalOverlay />

      <ModalContent
        borderRadius={rem(16)}
        zIndex="9999"
        bg="transparent"
        shadow="none"
      >
        <Editor
          isAdmin={admin && admin.name === name}
          colorsFields={MODAL_COLOR_FIELDS}
          position={{
            top: 0,
            left: 0,
          }}
          component={
            <Flex
              flexDirection="column"
              position="relative"
              w="100%"
              pb={rem(enable_visual_effects ? 42 : 20)}
              bg={
                enable_visual_effects
                  ? "transparent"
                  : getColor("modal-background", "background-body") ?? "white"
              }
              borderRadius={enable_visual_effects ? undefined : rem(4)}
            >
              <Background
                type={ActionIconTypes.MODAL_BACKDROP}
                color={
                  getColor("modal-background", "background-card") ?? "yellow"
                }
                top="0"
                left="0"
              />
              {children}
            </Flex>
          }
        />
      </ModalContent>
    </>
  );
};

export default ModalContentWrapper;

interface ModalContentWrapperProps {
  children?: React.ReactNode;
}
