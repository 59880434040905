import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/nl";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.locale("nl");

type DateType = string | Date | Dayjs;
type CalculateDatesType = "day" | "week" | "month" | "year";

export const defaultDateFormat = "DD-MM-YYYY";

export const stringToDate = (date: string, format?: string): Date => {
  const parsed = dayjs(date, format || defaultDateFormat, "nl");
  const newDate = new Date(parsed.year(), parsed.month(), parsed.date());
  return newDate;
};

export const dayIsAfter = (value?: DateType, minDate?: DateType): boolean => {
  if (!value || !minDate) throw new Error("Not all dates are provided");
  return dayjs(value).isAfter(dayjs(minDate));
};

export const dayIsBefore = (value?: DateType, maxDate?: DateType): boolean => {
  if (!maxDate || !value) throw new Error("Not all dates are provided");
  return dayjs(maxDate).isBefore(dayjs(value));
};

export const dateIsBetween = (
  date?: DateType,
  startDate?: DateType,
  endDate?: DateType
) => {
  if (!date || !startDate || !endDate)
    throw new Error("Not all dates are provided");
  return dayjs(date).isBetween(startDate, endDate, null, "[]");
};

export const subtractFromDate = (
  date?: DateType,
  amount?: number,
  type?: CalculateDatesType
): Dayjs | undefined => {
  if (!date || !amount) {
    console.error("No date provided to subtractFromDate");
    return undefined;
  }
  return dayjs(date).subtract(amount, type || "day");
};
