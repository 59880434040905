import { FC, useState } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
} from "@chakra-ui/react";
import { rem } from "polished";
import { useTranslation } from "react-i18next";
import { CartItemType } from "src/types";
import CartProductCard from "./card";
import { motion } from "framer-motion";
import getSymbolFromCurrency from "currency-symbol-map";
import ModalContentWrapper from "../modal/modal-content-wrapper";
import { useColors } from "hooks/useColors";
import { CART_COLOR_FIELDS } from "src/constants";
import Editor from "features/editor";
import { usePartnerStore } from "store/partner";
import { useAuthStore } from "store/authentication";

const CartDeleteModal: FC<CartDeleteModalProps> = ({
  productName,
  isOpen,
  onClose,
  onProductDeleteConfirm,
}) => {
  const { t } = useTranslation();
  const [productDeleteLoad, setDeleteProductLoad] = useState(false);

  const removeProduct = async () => {
    try {
      setDeleteProductLoad(true);
      await onProductDeleteConfirm();
    } finally {
      setDeleteProductLoad(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContentWrapper>
        <Flex
          flexDirection="column"
          sx={{
            pt: rem(20),
          }}
        >
          <ModalHeader pt={rem(32)} pl={rem(32)} pr={rem(32)}>
            {t("common.areYouSure")}
          </ModalHeader>
          <ModalCloseButton borderRadius={rem(32)} p={rem(18)} mt={rem(12)} />
          <ModalBody pl={rem(32)} pr={rem(32)}>
            <Text> {t("cart.deleteConfirmation")}</Text>
            <Flex>
              <Text mt={rem(16)}>
                <span style={{ fontWeight: 700 }}>{productName}</span>{" "}
                {t("cart.deleteInsuranceMessage")}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter p={rem(32)}>
            <Flex justifyContent="end">
              <Button
                variant="outline"
                onClick={onClose}
                borderRadius={30}
                mr={3}
                data-testid="close-btn"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="primary"
                onClick={removeProduct}
                borderRadius={30}
                data-testid="delete-btn"
                isLoading={productDeleteLoad}
                disabled={productDeleteLoad}
              >
                {t("common.delete")}
              </Button>
            </Flex>
          </ModalFooter>
        </Flex>
      </ModalContentWrapper>
    </Modal>
  );
};

export default CartDeleteModal;

interface CartDeleteModalProps {
  productName: string;
  isOpen: boolean;
  onClose: () => void;
  onProductDeleteConfirm: () => Promise<void>;
}

export const CartMobileModal: FC<CartMobileModalProps> = ({
  isOpen,
  onClose,
  cartItemList,
  isFunnelStarted,
  onContinue,
  onCartItemDelete,
}) => {
  const { t } = useTranslation();
  const { getColor } = useColors();
  const { name } = usePartnerStore();
  const { admin } = useAuthStore();

  const container = {
    hidden: { x: -100 },
    show: {
      x: [-100, 10, 0],
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { x: -100 },
    show: { x: [-100, 10, 0], stiffness: 100 },
  };

  const calculateAmount = (items: CartItemType[]) => {
    let amount = 0;
    items.forEach((item) => {
      amount =
        amount +
        (Number(item?.insurance_product?.price)
          ? Number(item?.insurance_product?.price)
          : 0);
    });
    return amount.toFixed(2);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalContent
        borderRadius={0}
        zIndex="3"
        background={
          getColor("cart-mobile-background", "support-menu-background") ??
          "black"
        }
        height="100%"
        width="100%"
        m={0}
        overflow="auto"
      >
        <ModalCloseButton
          size="sx"
          color="white"
          mt={rem(12)}
          mr={rem(20)}
          zIndex="5"
        />
        <ModalBody
          px={rem(32)}
          position="relative"
          display="flex"
          justifyContent="center"
        >
          <Editor
            isAdmin={admin && admin.name === name}
            colorsFields={CART_COLOR_FIELDS}
            triggerColor="white"
            component={
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                w="100%"
                height="100%"
                maxW={rem(600)}
              >
                <Flex
                  w="100%"
                  height="80%"
                  pt={[rem(112), rem(50)]}
                  flexDirection="column"
                  pb={rem(24)}
                >
                  <Text
                    fontSize={rem(24)}
                    lineHeight={rem(24)}
                    fontWeight="bold"
                    color={
                      getColor("cart-mobile-title", "support-menu-text") ??
                      "white"
                    }
                  >
                    {t("cart.selectedInsurances")}
                  </Text>
                  <Flex justifyContent="center">
                    <motion.div
                      variants={container}
                      initial="hidden"
                      animate="show"
                      style={{
                        width: "100%",
                        maxHeight: "60vh",
                        overflow: "scroll",
                        marginTop: rem(24),
                      }}
                    >
                      {cartItemList?.map(
                        ({ id, insurance_product, list_items }) => (
                          <motion.div key={id} variants={item}>
                            <CartProductCard
                              key={id}
                              name={insurance_product.name ?? ""}
                              amount={
                                Number(insurance_product?.price)
                                  ? Number(insurance_product?.price)
                                  : 0
                              }
                              onDelete={(e) => onCartItemDelete(e)}
                              listItems={list_items}
                              currency={insurance_product.price_currency}
                              isFunnelStarted={isFunnelStarted}
                              cartItemId={id}
                              netPrice={insurance_product?.net_price}
                            />
                          </motion.div>
                        )
                      )}
                    </motion.div>
                  </Flex>
                </Flex>
              </Flex>
            }
          />
        </ModalBody>
        <ModalFooter
          px={rem(32)}
          position="relative"
          display="flex"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            w="100%"
            h="100%"
            maxW={rem(600)}
          >
            <Flex justifyContent="space-between" width="100%" mb={rem(24)}>
              <Text
                lineHeight={rem(24)}
                color={
                  getColor("cart-mobile-card-text", "support-menu-text") ??
                  "white"
                }
              >
                {t("cart.total")}
              </Text>
              {Boolean(
                Number(String(calculateAmount(cartItemList) ?? "0.00"))
              ) && (
                <Flex alignItems="center">
                  <Text
                    textAlign="center"
                    color={
                      getColor("cart-mobile-card-text", "support-menu-text") ??
                      "white"
                    }
                  >
                    {Boolean(cartItemList.length) && (
                      <>
                        {getSymbolFromCurrency(
                          cartItemList[0]?.insurance_product.price_currency ??
                            "EUR"
                        )}
                      </>
                    )}{" "}
                    {calculateAmount(cartItemList)} {t("common.perMonthShort")}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Button
              variant="primary"
              isDisabled={cartItemList.length === 0}
              borderRadius={30}
              sx={{
                height: rem(56),
              }}
              px={rem(110)}
              outline="0"
              _focus={{
                outline: "0",
              }}
              onClick={isFunnelStarted ? onClose : onContinue}
              fontSize={rem(18)}
              lineHeight={rem(24)}
              fontWeight={500}
              data-testid="checkout-btn"
            >
              {t(isFunnelStarted ? "common.close" : "common.continue")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface CartMobileModalProps {
  isOpen: boolean;
  onClose: () => void;
  cartItemList: CartItemType[];
  isFunnelStarted: boolean;
  onContinue: () => void;
  onCartItemDelete: (productId: string) => void;
}
