import { FC, useEffect, useState } from "react";
import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import PrivateLayout from "features/shared/layout/private-layout";
import { HeaderStepRouteEnum } from "src/constants";
import { useStepStore } from "store/steps";
import { t } from "i18next";
import { rem } from "polished";
import { usePartnerStore } from "store/partner";
import { createVerificationSession } from "services/api/identity-verification";
import { getStripeKey, STRIPE_ACCOUNT } from "services/api/helpers/stripe";
import { useCartStore } from "store/cart";
import FraudLoader from "features/shared/loader/fraud-loader";
import { useNavigate } from "react-router-dom";
import { useWebsockets } from "hooks/useWebsockets";
import { useColors } from "hooks/useColors";
import { loadStripe } from "@stripe/stripe-js";

const IdentityVerificationPage: FC = () => {
  const { setCurrentStepId } = useStepStore();
  const { messageHandler } = useWebsockets();
  const { brand } = usePartnerStore();
  const { entryDate, cartItemList } = useCartStore();
  const toast = useToast();
  const { getColor } = useColors();
  const navigation = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  //Calling Stripe modal after getting the client_secret
  const verifyIdentityModal = async (client_secret: any) => {
    try {
      const stripe: any = window && window.Stripe?.(getStripeKey());
      const verifyIdentity = await stripe?.verifyIdentity(client_secret);
      if (verifyIdentity.error === null) {
        setShowLoader(true);
      }
      return verifyIdentity;
    } catch (error) {
      setShowLoader(false);
      toast({
        title: String(error),
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  //Getting the verification session if the product requires identity verification
  const getVerificationSession = async () => {
    setIsStripeLoading(true);
    const require_identity_verification = cartItemList.filter(
      (cartItems) => !!cartItems.insurance_product.require_identity_verification
    );
    try {
      const session = await createVerificationSession(
        require_identity_verification[0].insurance_product.id,
        entryDate ? entryDate : "2021-09-01"
      );
      verifyIdentityModal(session.client_secret);
      messageHandler(session.room_name);
      setIsStripeLoading(false);
    } catch (error) {
      navigation("/checkout/failure");
      toast({
        title: String(error),
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    loadStripe(getStripeKey(), {
      stripeAccount: STRIPE_ACCOUNT,
    });
    setCurrentStepId(HeaderStepRouteEnum.CHECKOUT);
  }, []);

  return (
    <PrivateLayout showCart={false}>
      {showLoader && <FraudLoader />}
      <Flex
        w="inherit"
        h={"full"}
        mt={"20"}
        justifyContent={"center"}
        alignItems={"baseline"}
      >
        <Flex
          direction="column"
          mb={[24, 24, 16]}
          maxW={rem(544)}
          sx={{
            zIndex: 3,
            bg: "white",
          }}
        >
          <Text
            fontSize={[rem(32), rem(48)]}
            lineHeight={[rem(36), rem(48)]}
            fontWeight="bold"
            color={getColor("question-title", "text")}
            wordBreak="normal"
          >
            {t("checkout.identityVerification.title")}
          </Text>
          <Text
            mt={rem(24)}
            fontSize={rem(18)}
            lineHeight={rem(24)}
            fontWeight="400"
            color={getColor("question-description", "text")}
            dangerouslySetInnerHTML={{
              __html: t("checkout.identityVerification.description") ?? "",
            }}
          />

          <Button
            mt={rem(24)}
            variant="primary"
            onClick={() => getVerificationSession()}
            borderRadius={30}
            display="flex"
            alignSelf="baseline"
            height={rem(56)}
            _hover={{
              bg: getColor("button-solid-hover-background"),
              color: "white",
            }}
            fontFamily={brand?.font_primary}
            fontSize={rem(18)}
            lineHeight={rem(24)}
            fontWeight={500}
            isLoading={isStripeLoading}
            data-testid="toOverview-btn"
          >
            <Text m={rem(8)}>
              {t("checkout.identityVerification.button.text")}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </PrivateLayout>
  );
};

export default IdentityVerificationPage;
