import { usePartnerStore } from "store/partner";

export const COLORS = {
  // text colors
  title: "#000000",
  subtitle: "#000000",
  text: "#000000",
  // button colors
  "button-solid-background": "#000000",
  "button-solid-text": "#ffffff",
  "button-solid-border": "#000000",
  "button-solid-hover-background": "#454545",
  "button-solid-hover-text": "#ffffff",
  "button-solid-hover-border": "#000000",
  "button-ghost-background": "#ffffff",
  "button-ghost-text": "#000000",
  "button-ghost-border": "#000000",
  "button-ghost-hover-background": "#000000",
  "button-ghost-hover-border": "#000000",
  "button-ghost-hover-text": "#ffffff",
  // background colors
  "background-body": "#ffffff",
  "background-card": "#EBFF00",
  "background-illustration": "#B5C9FF",
  // loader colors
  "loader-illustration": "#B5C9FF",
  "loader-text": "#ffffff",
  "loader-bg": "#000000",
  // accent colors
  "accent-positive-1": "#B5C9FF",
  "accent-positive-2": "#B5C9FF",
  "accent-positive-3": "#B5C9FF",
  "accent-negative-1": "#FB9090",
  // input colors
  "input-label-background": "#B5C9FF",
  // support menu colors
  "support-menu-background": "#000000",
  "support-menu-hover": "#ffffff",
  "support-menu-text": "#ffffff",
  "support-menu-text-hover": "#ffffff",
  // list marking colors
  "number-marking-background": "#B5C9FF",
  // product card colors
  "product-card-background": "#EBFF00",
  "product-title": "#000000",
  "product-price": "#000000",
  "product-desc": "#000000",
  "product-tag-text": "#000000",
  "product-tag-positive": "#ffffff",
  "product-tag-negative": "#FB9090",
  "product-readmore-text": "#000000",
  "product-right-arrow": "#000000",
  "product-shield": "#000000",
  "product-list-check-icon": "#000000",
  // cart colors
  "cart-card-background": "#B5C9FF",
  "cart-card-text": "#000000",
  "cart-card-seperator": "#00000033",
  "cart-title": "#000000",
  "cart-empty-description": "#000000",
  "cart-empty-icon": "#000000",
  "cart-empty-background": "#FAFAFA",
  "cart-counter-background": "#000000",
  "cart-counter-text": "#ffffff",
  "cart-amount": "#000000",
  "cart-border": "#000000",
  "cart-card-delete-icon": "#000000",
  "cart-card-delete-icon-hover": "#000000",
  "cart-mobile-background": "#000000",
  "cart-mobile-title": "#ffffff",
  "cart-mobile-card-text": "#ffffff",
  "cart-mobile-icon": "#ffffff",
  "cart-mobile-counter-background": "#000000",
  "cart-mobile-counter-text": "#ffffff",
  // question colors
  "question-info": "#000000",
  "question-title": "#000000",
  "question-description": "#000000",
  "question-list-item": "#000000",
  "question-list-selected-item": "#FAFAFA",
  "question-popular-label-color": "#13100D",
  "question-popular-label-background": "#E6B1FF",
  "question-card-background": "#B5C9FF",
  "quiz-info-background": "#f5f5f5",
  "quiz-info-icon": "#8e8e8e",
  "quiz-info-text": "#000000",
  // steps colors
  "steps-text": "#000000",
  "steps-label-background": "#B5C9FF",
  // profession colors
  "profession-right-arrow": "#000000",
  "profession-title": "#000000",
  "profession-description": "#000000",
  "profession-background-card": "#B5C9FF",
  "profession-item": "#000000",
  "profession-selected-item": "#FAFAFA",
  // modal colors
  "modal-background": "#B5C9FF",
  // confetti
  "confetti-color": "#B5C9FF",
  // policy card
  "policy-card-background": "#EBFF00",
  "policy-title": "#000000",
  "policy-price": "#000000",
  "policy-desc": "#000000",
  "policy-tag-text": "#000000",
  "policy-tag-positive": "#ffffff",
  "policy-tag-end-positive": "#B5C9FF",
  "policy-readmore-text": "#000000",
  "policy-right-arrow": "#000000",
  "policy-shield": "#000000",
  "policy-list-check-icon": "#000000",
  // navigation pane
  "nav-pane-icon-color": "#000000",
  "nav-pane-item-bg": "#ffffff",
  "nav-pane-item-text": "#000000",
  "nav-pane-item-active-bg": "#EBFF00",
  "nav-pane-item-active-text": "#000000",
  "nav-pane-item-hover-bg": "#ffffff",
  "nav-pane-item-hover-text": "#000000",
  "nav-pane-item-border": "#000000",
  "nav-pane-count-bg": "#B5C9FF",
  // PDP document card
  "pdp-document-title": "#000000",
  "pdp-document-card-background": "#CEE9EA",
  "pdp-document-active-card-background": "#B4FB9C",
  "pdp-document-old-card-background": "#D9D9D9",
  "pdp-document-card-text": "#000000",
  "pdp-document-card-icon": "#006A70",
  // PDP info card
  "pdp-info-title": "#000000",
  "pdp-info-card-background": "#CEE9EA",
  "pdp-info-card-text": "#000000",
  "pdp-info-card-description": "#000000",
  "pdp-info-card-icon": "#006A70",
  // to be deleted
  "readmore-color": "#000000",
  "accent-negative-2": "#000000",
  "accent-negative-3": "#000000",
  // AOV review page
  "question-border-color": "#9f9f9f",
};

export const useColors = () => {
  const { colors } = usePartnerStore();
  const getColor = (
    colorName: keyof typeof COLORS,
    fallbackColorName?: keyof typeof COLORS
  ) => {
    let fallbackColor = undefined;
    const filteredColor = colors?.find(
      (color: { key: string; value: string }) => color.key === colorName
    )?.value;

    fallbackColor =
      fallbackColorName &&
      colors?.find(
        (color: { key: string; value: string }) =>
          color.key === fallbackColorName
      )?.value;

    return filteredColor ?? fallbackColor ?? COLORS[colorName] ?? undefined;
  };

  return {
    getColor,
  };
};
