import { hexToRGB } from "@utils/colors";
import { Sidebar, VisibilityEnum } from "alicia-design-system";
import { useColors } from "hooks/useColors";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { NavList } from "src/constants/navigation";

const NavigationPane: FC = () => {
  const [activeRoute, setActiveRoute] = useState<string>("/user/details");
  const location = useLocation();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const { getColor } = useColors();
  const navList = NavList({ color: getColor("nav-pane-icon-color", "text") });

  useEffect(() => {
    if (location.pathname.includes("policy")) {
      setActiveRoute(
        navList.find((item) => item.id === "policyOverview")?.route ?? ""
      );
    } else {
      setActiveRoute(location.pathname);
    }
  }, [location.pathname]);

  const onRouteClick = (route: string) => {
    navigation(route);
  };

  return (
    <Sidebar
      visibility={VisibilityEnum.FULL}
      menu={navList.map((item: any) => {
        return {
          id: item.id,
          label: t(item.label),
          route: item.route,
          icon: item.icon,
          subMenu: item.subMenu,
          count: item.id === "inbox" ? 5 : undefined, //TODO: hardcoded for now have to update with the logic after the inbox apis are implemented
          mt: item.id === "inbox" ? 20 : 0,
        };
      })}
      onClick={onRouteClick}
      activeRoute={activeRoute}
      colors={{
        menuItemBackgroundColor: getColor("nav-pane-item-bg") ?? "transparent",
        menuItemTextColor: getColor("nav-pane-item-text", "text"),
        menuItemActiveBackgroundColor: getColor(
          "nav-pane-item-active-bg",
          "input-label-background"
        ),
        menuItemActiveTextColor: getColor("nav-pane-item-active-text", "text"),
        menuItemHoverBackgroundColor:
          getColor("nav-pane-item-hover-bg") ?? "rgba(0, 0, 0, 0.05)",
        menuItemHoverTextColor: getColor("nav-pane-item-hover-text", "text"),
        menuItemBorderColor: hexToRGB(
          getColor("nav-pane-item-border", "text") ?? "black",
          "0.2"
        ),
        countBackgroundColor:
          getColor("nav-pane-count-bg", "background-illustration") ?? "yellow",
      }}
    />
  );
};

export default NavigationPane;
