import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { PartnerDetailsType } from "../types/types";
import { getPartners } from "services/api/partners";
import { PARTNER_STORE } from "../constants/constant";

const initialPartnerState = {
  name: "Alicia",
  gtm: "",
  gtmId: "",
  languages: null,
  translations: null,
  default_language: null,
  available_languages: [],
  hideKvk: false,
  redirectCalculator: false,
  manualCompanyInformationEnabled: false,
  validatePolicyStartDate: false,
  loaded: false,
  brand: undefined,
  colors: undefined, //https://aliciainsurance.atlassian.net/wiki/spaces/TECH/pages/295567361/Alicia+2.0+Colors
  support_email: "",
  support_phone_number: "",
  trengo_key: import.meta.env.VITE_APP_TRENGO_TOKEN,
  is_direct: false,
  expo_ip: undefined,
  checkinReturnURL: undefined,
  allow_manual_company_information_input: false,
  enable_visual_effects: false,
  hide_professions_on_welcome_screen: false,
  hide_profession_page: false,
};

export const usePartnerStore = create(
  devtools(
    persist<PartnerDetailsType>(
      (set, get) => ({
        ...initialPartnerState,
        getPartner: async () => {
          try {
            const partner = await getPartners();
            set(() => ({
              name: partner.name,
              languages: partner.languages,
              default_language: partner.default_language,
              translations: partner.translations,
              available_languages: partner.available_languages,
              hideKvk: partner.hide_kvk,
              allow_manual_company_information_input:
                partner.allow_manual_company_information_input,
              redirectCalculator: partner.login_via_email_available,
              gtm: partner.google_tag_manager_code,
              gtmId: partner.google_tag_manager_id,
              validatePolicyStartDate: partner.validate_policy_start_date,
              brand: partner.brand,
              colors: partner.colors,
              loaded: true,
              support_email: partner.support_email,
              support_phone_number: partner.support_phone_number,
              trengo_key:
                partner.trengo_key ?? import.meta.env.VITE_APP_TRENGO_TOKEN,
              is_direct: partner.is_direct,
              enable_visual_effects: partner.enable_visual_effects,
              hide_professions_on_welcome_screen:
                partner.hide_professions_on_welcome_screen,
              hide_profession_page: partner.hide_profession_page,
            }));
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        setExpoIp: (expo_ip: string) => {
          set(() => ({
            expo_ip,
          }));
        },
        setCheckInReturnUrl: (returnUrl: string) => {
          set(() => ({
            checkinReturnURL: returnUrl,
          }));
        },
        clearPartner: () => {
          set(() => initialPartnerState);
        },
        updatePartnerColors: (changedColors) => {
          const colors = get().colors;
          if (colors) {
            for (const changedColor of changedColors) {
              const index = colors.findIndex(
                (color) => color.id === changedColor.id
              );
              if (index > -1) {
                colors[index] = changedColor;
              }
            }
            set(() => ({
              colors,
            }));
          }
        },
      }),
      {
        name: PARTNER_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
