import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import ContentLoader from "react-content-loader";
import { rem } from "polished";
import { media } from "@utils/media";

const Placeholder: FC = () => {
  return (
    <Flex
      gap={8}
      width="100%"
      justifyContent="center"
      sx={{
        flexDirection: "row",
        [media.large]: {
          flexDirection: "column",
        },
      }}
    >
      <PlaceholderCard />
      <PlaceholderCard />
      <PlaceholderCard />
    </Flex>
  );
};

export default Placeholder;

const PlaceholderCard: FC = () => {
  return (
    <Flex
      width="100%"
      sx={{
        border: `none`,
        padding: rem(24),
        [media.large]: {
          paddingTop: rem(16),
          paddingBottom: rem(16),
        },
      }}
      bg="#f0f0f0"
    >
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 400 210"
        backgroundColor="#ddd9d9"
        foregroundColor="#e0e0e0"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="18" />
        <rect x="0" y="38" rx="5" ry="5" width="90%" height="18" />
        <rect x="0" y="75" rx="5" ry="5" width="90%" height="10" />
        <rect x="0" y="90" rx="5" ry="5" width="80%" height="10" />
        <rect x="0" y="125" rx="5" ry="5" width="80%" height="10" />
        <rect x="0" y="153" rx="5" ry="5" width="100%" height="62" />
      </ContentLoader>
    </Flex>
  );
};
